<ion-header>
  <ion-toolbar>
    <ion-title>App Installieren</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="inner-content p-4">
  <h1>App installieren</h1>

  <div>

    <div class="flex">
      Um die App auf deinem iPhone oder iPad zu installieren, klicke auf das Teilen-Symbol in deinem Browser (z.B. in
      der Suchleiste oben rechts oder unten in der Mitte):
      <img src="assets/icons/share-apple.svg" alt="iOS Installieren" class="h-12 w-12 bg-white"/>
    </div>

    <div class="mt-3">
      In dem sich öffnenden Menü klicke auf "Zum Home-Bildschirm". Danach kannst du den Browser schließen und die App
      von deinem Home-Bildschirm starten.
    </div>
  </div>
</div>

<ion-footer>
  <ion-toolbar>
    <ion-button expand="block" (click)="close()">OK</ion-button>
  </ion-toolbar>
</ion-footer>
