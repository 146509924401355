import {inject, Injectable} from "@angular/core";
import {firstValueFrom, Observable} from "rxjs";
import {ApiService} from "@app/services";
import {SettingsRepository} from "@app/repositories/settings.repository";
import {PollsRepository} from "@app/repositories/polls.repository";
import {Poll, PollForm} from "@interfaces/poll";

@Injectable({providedIn: 'root'})
export class PollsService extends ApiService {

  private pollsRepository: PollsRepository = inject(PollsRepository);
  private settingsRepository: SettingsRepository = inject(SettingsRepository);

  async maybeRefreshPolls() {
    console.log(`${this.constructor.name}.maybeRefreshPolls`);

    const updatedAt: string | null = await firstValueFrom(this.settingsRepository.pollsUpdatedAt$);
    if (!updatedAt) {
      console.log(`${this.constructor.name}.maybeRefreshPolls loading new polls from API with no updatedAt date`);
      await this.getAllFromApi();
      return;
    }

    const updatedAtDate = new Date(updatedAt ?? '');
    if (updatedAtDate && updatedAtDate.getTime() < new Date().getTime() - 1000 * 60 * 5) { // 5 minutes
      console.log(`${this.constructor.name}.maybeRefreshPolls loading new polls from API with outdated updatedAt date`);
      await this.getAllFromApi();
      return;
    }
  }

  fetch(): Observable<Poll[]> {
    console.log(`${this.constructor.name}.fetch`);
    return this.get(`/polls`);
  }

  private cachePollsCollection(polls: Poll[]) {
    console.log(`${this.constructor.name}.cachePollsCollection`);
    this.settingsRepository.setPollsUpdatedAt();
    this.pollsRepository.update(polls);
  }

  private async cachePollItem(poll: Poll) {
    console.log(`${this.constructor.name}.cachePollItem`, poll);
    this.pollsRepository.add(poll);
  }

  async getOneFromApi(id: string): Promise<Poll> {
    console.log(`${this.constructor.name}.getOneFromApi`, id);
    const poll = await firstValueFrom(this.get(`/polls/${id}`)).catch(() => null);

    if (poll) {
      this.pollsRepository.add(poll);
    }

    return poll;
  }

  async getAllFromApi(): Promise<Poll[]> {
    return firstValueFrom(this.fetch()).then((fetchedPolls: Poll[]) => {
      this.cachePollsCollection(fetchedPolls);
      return fetchedPolls;
    }).catch(() => []);
  }

  async clearCache() {
    console.log(`${this.constructor.name}.clearCache`);
    this.settingsRepository.removePollsUpdatedAt();
    this.pollsRepository.update([]);
  }

  create(poll: PollForm): Observable<Poll> {
    console.log(`${this.constructor.name}.create`, poll);
    return this.post(`/polls`, poll);
  }

  update(poll: PollForm): Observable<Poll> {
    console.log(`${this.constructor.name}.update`, poll);
    return this.put(`/polls/${poll.id}`, poll);
  }

  async trackView(poll: Poll): Promise<Poll> {
    console.log(`${this.constructor.name}.trackView`);
    const updatedPoll = await firstValueFrom(this.post(`/polls/${poll.id}/track-view`, {}));

    await this.cachePollItem(updatedPoll);
    return updatedPoll;
  }

  async vote(poll: Poll, questions: number[]): Promise<Poll> {
    const updatedPoll = await firstValueFrom(this.post<Poll>(`/polls/${poll.id}/vote`, {questions}));
    await this.cachePollItem(updatedPoll);

    return updatedPoll;
  }

  async destroy(poll: Poll) {
    console.log(`${this.constructor.name}.delete`, poll);
    const deletedPoll = await firstValueFrom(this.delete(`/polls/${poll.id}`));
    this.pollsRepository.remove(poll);
  }
}
