import {Component, ElementRef, inject} from '@angular/core';
import {Place} from "@app/interfaces";
import {IonButton, IonButtons, IonFooter, IonHeader, IonIcon, IonTitle, IonToolbar} from "@ionic/angular/standalone";
import {AuthService} from "@app/services";
import {close} from "ionicons/icons";
import {addIcons} from "ionicons";

@Component({
  selector: 'app-install-ios-sheet',
  templateUrl: './install-ios-sheet.component.html',
  styleUrls: ['./install-ios-sheet.component.scss'],
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonFooter,
    IonButton,
    IonButtons,
    IonIcon,
  ]
})
export class InstallIosSheetComponent {

  public place: Place | undefined;

  public authService: AuthService = inject(AuthService);
  private el: ElementRef = inject(ElementRef);

  constructor() {
    console.log(`${this.constructor.name}.constructor`);
    addIcons({close});
  }

  close() {
    this.el.nativeElement.dispatchEvent(new CustomEvent('close-install-ios-sheet', {bubbles: true}));
  }
}
