import {Routes} from '@angular/router';
import {TabsPage} from "@app/pages/tabs/tabs.page";
import {AuthGuard} from "@app/guards/auth-guard.service";
import {NgxSeo} from "@avivharuzi/ngx-seo";
import {ArticlesResolver} from "@app/resolver/articles.resolver";
import {ArticlesByCategoryResolver} from "@app/resolver/articles-by-category.resolver";
import {ArticleResolver} from "@app/resolver/article.resolver";
import {ArticleCategoryResolver} from "@app/resolver/article-category.resolver";
import {PollsResolver} from "@app/resolver/polls.resolver";
import {PollResolver} from "@app/resolver/poll.resolver";

const SEO_NEWS: NgxSeo = {
  title: 'News | Pfaffenhofen App',
  meta: {
    description: 'Die Pfaffenhofen-App ist eine Plattform für Neuigkeiten, Veranstaltungen und Informationen aus Pfaffenhofen an der Roth und Umgebung.',
    keywords: 'Pfaffenhofen an der Roth, App, Neuigkeiten, Veranstaltungen, Informationen',
    canonical: window.location.origin + '/news'
  }
}

const SEO_EVENTS: NgxSeo = {
  title: 'Events | Pfaffenhofen App',
  meta: {
    description: 'Finde die spannende Veranstaltungen und Events von Firmen und Vereinen aus Pfaffenhofen an der Roth und Umgebung.',
    keywords: 'Pfaffenhofen an der Roth, App, Neuigkeiten, Veranstaltungen, Informationen',
    canonical: window.location.origin + '/events'
  }
}

const SEO_PLACES: NgxSeo = {
  title: 'Orte | Pfaffenhofen App',
  meta: {
    description: 'Entdecke Firmen, Vereine und Orte in Pfaffenhofen an der Roth und Umgebung.',
    keywords: 'Pfaffenhofen an der Roth, App, Neuigkeiten, Veranstaltungen, Informationen',
    canonical: window.location.origin + '/places'
  }
}

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/news',
    pathMatch: 'full'
  },
  {
    path: '',
    component: TabsPage,
    children: [
      {
        path: 'search',
        loadComponent: () => import('./app/pages/search/search.page').then(m => m.SearchPage),
      },

      {
        path: 'news',
        loadComponent: () => import('./app/pages/news/news.page').then(m => m.NewsPage),
        data: {seo: SEO_NEWS}
      },
      {
        path: 'news/:placeSlug/:newsSlug',
        loadComponent: () => import('./app/pages/news/news-detail/news-detail.page').then(m => m.NewsDetailPage),
      },
      {
        path: 'news/:id',
        loadComponent: () => import('./app/pages/news/news-detail/news-detail.page').then(m => m.NewsDetailPage),
      },
      {
        path: 'events',
        loadComponent: () => import('./app/pages/events/events.page').then(m => m.EventsPage),
        data: {seo: SEO_EVENTS}
      },
      {
        path: 'events/:placeSlug/:eventSlug',
        loadComponent: () => import('./app/pages/events/event-detail/event-detail.page').then(m => m.EventDetailPage),
      },
      {
        path: 'events/:id',
        loadComponent: () => import('./app/pages/events/event-detail/event-detail.page').then(m => m.EventDetailPage),
      },
      {
        path: 'places',
        redirectTo: 'places/map',
      },
      {
        path: 'places/map',
        loadComponent: () => import('./app/pages/places/places-map/places-map.page').then(m => m.PlacesMapPage),
        data: {seo: SEO_PLACES}
      },
      {
        path: 'places/detail/:slug',
        loadComponent: () => import('./app/pages/places/place-detail/place-detail.page').then(m => m.PlaceDetailPage),
      },
      {
        path: 'categories',
        loadComponent: () => import('./app/pages/categories/categories.page').then(mod => mod.CategoriesPage),
      },
      {
        path: 'categories/:id',
        loadComponent: () => import('./app/pages/categories/category-detail/category-detail.page').then(mod => mod.CategoryDetailPage),
      },

      {
        path: 'articles',
        loadComponent: () => import('./app/pages/articles/list/list.page').then(m => m.ListPage),
        resolve: {articles: ArticlesResolver, polls: PollsResolver}
      },
      {
        path: 'articles/polls/detail/:pollSlug',
        loadComponent: () => import('./app/pages/articles/poll-detail/poll-detail.page').then(m => m.PollDetailPage),
        resolve: {poll: PollResolver}
      },
      {
        path: 'articles/:categorySlug',
        loadComponent: () => import('./app/pages/articles/category/category.page').then(m => m.CategoryPage),
        resolve: {articles: ArticlesByCategoryResolver, category: ArticleCategoryResolver}
      },
      {
        path: 'articles/:categorySlug/detail/:articleSlug',
        loadComponent: () => import('./app/pages/articles/article-detail/article-detail.page').then(m => m.ArticleDetailPage),
        resolve: {article: ArticleResolver, category: ArticleCategoryResolver}
      },
      {
        path: 'user/login',
        loadComponent: () => import('./app/pages/user/login/login.page').then(m => m.LoginPage),
      },
      {
        path: 'user/register',
        loadComponent: () => import('./app/pages/user/register/register.page').then(m => m.RegisterPage),
      },
      {
        path: 'user/forgot-password',
        loadComponent: () => import('./app/pages/user/forgot-password/forgot-password.page').then(m => m.ForgotPasswordPage),
      },
      {
        path: 'user/details',
        loadComponent: () => import('./app/pages/user/user-details/user-details.page').then(m => m.UserDetailsPage),
        canActivate: [AuthGuard]
      },
      {
        path: 'user/preferences',
        loadComponent: () => import('./app/pages/user/preferences/preferences.page').then(m => m.PreferencesPage),
        canActivate: [AuthGuard]
      },
      {
        path: 'user/voluntary-activities',
        loadComponent: () => import('./app/pages/user/voluntary-activities/voluntary-activities.page').then(m => m.VoluntaryActivitiesPage),
        canActivate: [AuthGuard]
      },
      {
        path: 'privacy',
        loadComponent: () => import('./app/pages/privacy/privacy.page').then(m => m.PrivacyPage),
      },
      {
        path: 'imprint',
        loadComponent: () => import('./app/pages/imprint/imprint.page').then(m => m.ImprintPage),
      },
      {
        path: 'contact',
        loadComponent: () => import('./app/pages/contact/contact.page').then( m => m.ContactPage)
      },
      {
        path: '',
        redirectTo: '/news',
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: '/news',
      }
    ]
  },
];
