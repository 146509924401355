import {Component, computed, inject, OnDestroy, OnInit, Signal} from '@angular/core';
import {
  AlertController,
  IonApp,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  Platform
} from "@ionic/angular/standalone";
import {AuthService, MessagingService} from "@app/services";
import {SwPush, SwUpdate} from "@angular/service-worker";
import {firstValueFrom, interval} from "rxjs";
import {CacheService} from "@services/cache.service";
import {register} from 'swiper/element/bundle';
import {A2hsBrowserPromptComponent} from "@app/components/a2hs-browser-prompt/a2hs-browser-prompt.component";
import {RouterLink} from "@angular/router";
import {flare} from "@flareapp/flare-client";
import {EventsService, NewsService, PlacesService} from "@services/api";
import {OnboardingSheetService} from "@services/onboarding-sheet.service";
import {environment} from "@env/environment";
import {PlacesRepository} from "@app/repositories/places.repository";
import {NewsRepository} from "@app/repositories/news.repository";
import {EventsRepository} from "@app/repositories/events.repository";
import {SettingsRepository} from "@app/repositories/settings.repository";
import {ArticlesService} from "@services/api/articles.service";
import {ArticlesRepository} from "@app/repositories/articles.repository";
import {ArticleCategoriesRepository} from "@app/repositories/article-categories.repository";
import {ArticleCategoriesService} from "@services/api/article-categories.service";
import {User} from "@app/interfaces";
import {PollsRepository} from "@app/repositories/polls.repository";
import {PollsService} from "@services/api/polls.service";

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  imports: [IonApp, IonRouterOutlet, A2hsBrowserPromptComponent, IonSplitPane, IonMenu, IonContent, IonList, IonMenuToggle, IonItem, IonLabel, RouterLink, IonFooter]
})
export class AppComponent implements OnInit, OnDestroy {

  private platform: Platform = inject(Platform);
  private authService: AuthService = inject(AuthService);
  private swUpdate: SwUpdate = inject(SwUpdate);
  private swPush: SwPush = inject(SwPush);
  private alertCtrl: AlertController = inject(AlertController);
  private cacheService: CacheService = inject(CacheService);
  private messagingService: MessagingService = inject(MessagingService);

  private newsService: NewsService = inject(NewsService);
  private eventsService: EventsService = inject(EventsService);
  private placesService: PlacesService = inject(PlacesService);
  private articlesService: ArticlesService = inject(ArticlesService);
  private articleCategoriesService: ArticleCategoriesService = inject(ArticleCategoriesService);
  private pollsService: PollsService = inject(PollsService);

  private settingsRepository: SettingsRepository = inject(SettingsRepository);
  private newsRepository: NewsRepository = inject(NewsRepository);
  private eventsRepository: EventsRepository = inject(EventsRepository);
  private placesRepository: PlacesRepository = inject(PlacesRepository);
  private articlesRepository: ArticlesRepository = inject(ArticlesRepository);
  private articleCategoriesRepository: ArticleCategoriesRepository = inject(ArticleCategoriesRepository);
  private pollsRepository: PollsRepository = inject(PollsRepository);

  private onboardingSheetService: OnboardingSheetService = inject(OnboardingSheetService);

  private updateInterval: any;

  protected user: Signal<User | null> = computed(() => this.authService.currentUser());

  constructor() {
    console.log(`${this.constructor.name}.constructor`);
    this.initializeApp();
  }

  async ngOnInit() {
    console.log(`${this.constructor.name}.ngOnInit`);

    // check for app updates every 15 minutes
    this.updateInterval = interval(60000 * 15).subscribe(() => {
      this.checkAppUpdate();
    });

    await this.checkAppUpdate();

    this.swPush.messages.subscribe((message) => {
      console.log(`${this.constructor.name}.swPush.messages`, message);
    });

    this.swPush.notificationClicks.subscribe((click) => {
      console.log(`${this.constructor.name}.swPush.notificationClicks`, click);
    });
  }

  async initializeApp() {
    console.log(`${this.constructor.name}.initializeApp`);

    if (environment.production) {
      console.log(`${this.constructor.name}.initializeApp, flare enabled`);
      flare.light('0WIrhRmTZ6uois91bv71Ehj7BfFoKInM');
    }

    this.platform.ready().then(async () => {
      console.log(`${this.constructor.name}.initializeApp, platform ready`);

      await this.authService.checkAccessToken();
      await this.messagingService.getToken();

      this.initializeNewsRepository();
      this.initializeEventsRepository();
      this.initializePlacesRepository();
      this.initializeArticlesRepository();
      this.initializeArticleCategoriesRepository();
      this.initializePollsRepository();
    });

    setTimeout(async () => await this.onboardingSheetService.maybeShowOnboarding(), 1000);
  }

  private initializeArticleCategoriesRepository() {
    this.articleCategoriesRepository.articleCategories$.subscribe(async categories => {
      console.log(`${this.constructor.name}.articleCategories: got articles from repository:`, categories.length);
      if (!categories) {
        await this.articleCategoriesService.getAllFromApi();
      }

      const updatedAt: string | null = await firstValueFrom(this.settingsRepository.articlesUpdatedAt$);
      if (!updatedAt) {
        console.log(`${this.constructor.name}.articleCategories loading new articles from API with no updatedAt date`);
        await this.articleCategoriesService.getAllFromApi();
        return;
      }

      const updatedAtDate = new Date(updatedAt ?? '');
      if (updatedAtDate && updatedAtDate.getTime() < new Date().getTime() - 1000 * 60 * 5) { // 5 minutes
        console.log(`${this.constructor.name}.articleCategories loading new articles from API with outdated updatedAt date`);
        await this.articleCategoriesService.getAllFromApi();
        return;
      }
    });
  }

  private initializeArticlesRepository() {
    this.articlesRepository.articles$.subscribe(async articles => {
      console.log(`${this.constructor.name}.articles$: got articles from repository:`, articles.length);
      if (!articles) {
        await this.articlesService.getAllFromApi();
      }

      await this.articlesService.maybeRefreshArticles();
    });
  }

  private initializePlacesRepository() {
    this.placesRepository.places$.subscribe(async places => {
      console.log(`${this.constructor.name}.places$: got places from repository:`, places.length);
      if (!places) {
        await this.placesService.getAllFromApi();
      }

      const updatedAt: string | null = await firstValueFrom(this.settingsRepository.placesUpdatedAt$);
      if (!updatedAt) {
        console.log(`${this.constructor.name}.places$ loading new places from API with no updatedAt date`);
        await this.placesService.getAllFromApi();
        return;
      }

      const updatedAtDate = new Date(updatedAt ?? '');
      if (updatedAtDate && updatedAtDate.getTime() < new Date().getTime() - 1000 * 60 * 15) { // 15 minutes
        console.log(`${this.constructor.name}.places$ loading new places from API with outdated updatedAt date`);
        await this.placesService.getAllFromApi();
        return;
      }
    });
  }

  private initializeEventsRepository() {
    this.eventsRepository.events$.subscribe(async events => {
      console.log(`${this.constructor.name}.events$: got events from repository:`, events.length);
      if (!events) {
        console.log(`${this.constructor.name}.events$ loading new events from API with no events present`);
        await this.eventsService.getAllFromApi();
        return;
      }

      const updatedAt: string | null = await firstValueFrom(this.settingsRepository.eventsUpdatedAt$);
      if (!updatedAt) {
        console.log(`${this.constructor.name}.events$ loading new events from API with no updatedAt date`);
        await this.eventsService.getAllFromApi();
        return;
      }

      const updatedAtDate = new Date(updatedAt ?? '');
      if (updatedAtDate && updatedAtDate.getTime() < new Date().getTime() - 1000 * 60 * 15) { // 15 minutes
        console.log(`${this.constructor.name}.events$ loading new events from API with outdated updatedAt date`);
        await this.eventsService.getAllFromApi();
        return;
      }
    });
  }

  private initializeNewsRepository() {
    this.newsRepository.news$.subscribe(async news => {
      console.log(`${this.constructor.name}.news$: got news from repository:`, news.length);
      if (!news) {
        console.log(`${this.constructor.name}.news$ loading new news from API with no news present`);
        await this.newsService.getAllFromApi();
        return;
      }

      const updatedAt: string | null = await firstValueFrom(this.settingsRepository.newsUpdatedAt$);
      if (!updatedAt) {
        console.log(`${this.constructor.name}.news$ loading new news from API with no updatedAt date`);
        await this.newsService.getAllFromApi();
        return;
      }

      const updatedAtDate = new Date(updatedAt ?? '');
      if (updatedAtDate && updatedAtDate.getTime() < new Date().getTime() - 1000 * 60 * 15) { // 15 minutes
        console.log(`${this.constructor.name}.news$ loading new news from API with outdated updatedAt date`);
        await this.newsService.getAllFromApi();
        return;
      }
    });
  }

  private initializePollsRepository() {
    this.pollsRepository.polls$.subscribe(async polls => {
      console.log(`${this.constructor.name}.polls$: got polls from repository:`, polls.length);
      if (!polls) {
        console.log(`${this.constructor.name}.polls$ loading new polls from API with no polls present`);
        await this.pollsService.getAllFromApi();
        return;
      }

      await this.pollsService.maybeRefreshPolls();
    });
  }

  ngOnDestroy() {
    console.log(`${this.constructor.name}.ngOnDestroy`);
    this.updateInterval.unsubscribe();
  }

  async checkAppUpdate() {
    if (this.swUpdate.isEnabled) {
      const available = await this.swUpdate.checkForUpdate();
      console.log('new version available:', available);
      if (available) {
        const alert = await this.alertCtrl.create({
          header: 'Update verfügbar',
          message: 'Eine neue Version der App ist verfügbar. Möchtest du die App jetzt aktualisieren?',
          backdropDismiss: false,
          buttons: [
            {
              text: 'Aktualisieren',
              role: 'confirm',
              handler: async () => {
                await this.swUpdate.activateUpdate();
                await this.cacheService.clearCache();

                const url = window.location.href;
                const newUrl = this.urlWithRndQueryParam(url);
                await fetch(newUrl, {
                  headers: {
                    Pragma: 'no-cache',
                    Expires: '-1',
                    'Cache-Control': 'no-cache',
                  },
                });
                window.location.href = url;
                window.location.reload();
              }
            }
          ]
        });
        await alert.present();
      }
    }
  }

  urlWithRndQueryParam(url: string, paramName: string = '_z') {
    const ulrArr = url.split('#');
    const urlQry = ulrArr[0].split('?');
    const usp = new URLSearchParams(urlQry[1] || '');
    usp.set(paramName || '_z', `${Date.now()}`);
    urlQry[1] = usp.toString();
    ulrArr[0] = urlQry.join('?');
    return ulrArr.join('#');
  }
}
