import {Injectable} from "@angular/core";
import {createState, Store} from '@ngneat/elf';
import {News, Place} from "@app/interfaces";
import {
  deleteEntities,
  selectAllEntities,
  selectAllEntitiesApply,
  selectEntity,
  setEntities,
  upsertEntities,
  withEntities,
} from '@ngneat/elf-entities';
import {localStorageStrategy, persistState} from "@ngneat/elf-persist-state";

const {state, config} = createState(withEntities<News>());

const newsStore = new Store({name: 'news', state, config});

const persist = persistState(newsStore, {
  key: 'news',
  storage: localStorageStrategy,
});

@Injectable({providedIn: 'root'})
export class NewsRepository {
  news$ = newsStore.pipe(selectAllEntities());

  updateNews(news: News[]) {
    console.log(`${this.constructor.name}.updateNews`, news.length);
    newsStore.update(setEntities(news));
  }

  filterById(id: string) {
    return newsStore.pipe(selectEntity(id));
  }

  filterByPlace(place: Place) {
    console.log(`${this.constructor.name}.filterByPlace`, place);
    return newsStore.pipe(
      selectAllEntitiesApply({
        filterEntity: (e) => e.owner.id === place.id
      })
    );
  }

  filterBySlug(newsSlug: string) {
    return newsStore.pipe(
      selectAllEntitiesApply({
        filterEntity: (e) => e.slug === newsSlug
      })
    );
  }

  constructor() {
    console.log(`${this.constructor.name}.constructor`);

    this.news$.subscribe(news => {
    });
  }

  async addNews(news: News) {
    console.log(`${this.constructor.name}.addNews`, news);

    try {
      newsStore.update(upsertEntities(news));
    } catch (error) {
      console.error(`${this.constructor.name}.addNews: error`, error);
    }
  }

  remove(news: News) {
    console.log(`${this.constructor.name}.remove`, news);
    newsStore.update(deleteEntities(news.id));
  }
}
