import {Injectable} from "@angular/core";
import {ModalController} from "@ionic/angular/standalone";
import {UpdateEventSheetComponent} from "@app/components/events/update-event-sheet/update-event-sheet.component";
import {Event} from "@app/interfaces/event";

@Injectable({
  providedIn: 'root'
})
export class UpdateEventSheetService {
  private modal: HTMLIonModalElement | undefined;

  constructor(private modalController: ModalController) {
  }

  async open(model: Event) {
    console.log(`${this.constructor.name}.open`, model);

    this.modal = await this.modalController.create({
      component: UpdateEventSheetComponent,
      componentProps: {model: model},
      cssClass: 'auto-height',
    });

    this.modal.addEventListener('close-update-event-sheet', () => {
      this.close();
    });

    await this.modal.present();
  }

  async close() {
    await this.modal?.dismiss();
  }
}
