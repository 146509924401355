import {inject, Injectable} from "@angular/core";
import {Article, ArticleForm} from "@interfaces/article";
import {firstValueFrom, Observable} from "rxjs";
import {ApiService} from "@app/services";
import {SettingsRepository} from "@app/repositories/settings.repository";
import {ArticlesRepository} from "@app/repositories/articles.repository";

@Injectable({providedIn: 'root'})
export class ArticlesService extends ApiService {

  private articlesRepository: ArticlesRepository = inject(ArticlesRepository);
  private settingsRepository: SettingsRepository = inject(SettingsRepository);

  async maybeRefreshArticles() {
    console.log(`${this.constructor.name}.maybeRefreshArticles`);
    const updatedAt: string | null = await firstValueFrom(this.settingsRepository.articlesUpdatedAt$);
    if (!updatedAt) {
      console.log(`${this.constructor.name}.maybeRefreshArticles: loading new articles from API with no updatedAt date`);
      await this.getAllFromApi();
      return;
    }

    const updatedAtDate = new Date(updatedAt ?? '');
    if (updatedAtDate && updatedAtDate.getTime() < new Date().getTime() - 1000 * 60 * 5) { // 5 minutes
      console.log(`${this.constructor.name}.maybeRefreshArticles: loading new articles from API with outdated updatedAt date`);
      await this.getAllFromApi();
      return;
    }
  }

  fetch(): Observable<Article[]> {
    console.log(`${this.constructor.name}.fetch`);
    return this.get(`/articles`);
  }

  private cacheArticlesCollection(articles: Article[]) {
    console.log(`${this.constructor.name}.cacheArticles`);
    this.settingsRepository.setArticlesUpdatedAt();
    this.articlesRepository.update(articles);
  }

  private async cacheArticleItem(article: Article) {
    console.log(`${this.constructor.name}.cacheArticleItem`, article);
    this.articlesRepository.add(article);
  }

  async getOneFromApi(id: string): Promise<Article> {
    console.log(`${this.constructor.name}.getOneFromApi`, id);
    const article = await firstValueFrom(this.get(`/articles/${id}`)).catch(() => null);

    if (article) {
      this.articlesRepository.add(article);
    }

    return article;
  }

  async getAllFromApi(): Promise<Article[]> {
    return firstValueFrom(this.fetch()).then((fetchedArticles: Article[]) => {
      this.cacheArticlesCollection(fetchedArticles);
      return fetchedArticles;
    }).catch(() => []);
  }

  async clearCache() {
    console.log(`${this.constructor.name}.clearCache`);
    this.settingsRepository.removeArticlesUpdatedAt();
    this.articlesRepository.update([]);
  }

  create(article: ArticleForm): Observable<Article> {
    console.log(`${this.constructor.name}.create`, article);
    return this.post(`/articles`, article);
  }

  update(article: ArticleForm): Observable<Article> {
    console.log(`${this.constructor.name}.update`, article);
    return this.put(`/articles/${article.id}`, article);
  }

  requestContact(article: Article, message: string | null = null): Observable<{ success: boolean }> {
    console.log(`${this.constructor.name}.requestContact`, article);
    return this.post(`/articles/${article.id}/request-contact`, {message});
  }

  async trackView(article: Article): Promise<Article> {
    console.log(`${this.constructor.name}.trackView`);
    const updatedArticle = await firstValueFrom(this.post(`/articles/${article.id}/track-view`, {}));

    await this.cacheArticleItem(updatedArticle);
    return updatedArticle;
  }

  async destroy(article: Article) {
    console.log(`${this.constructor.name}.destroy`, article);
    const response = await firstValueFrom(this.delete(`/articles/${article.id}`));
    this.articlesRepository.remove(article);

    return response;
  }
}
