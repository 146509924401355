import {Component, ElementRef, inject, Input} from '@angular/core';
import {
  AlertController,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
  ToastController
} from "@ionic/angular/standalone";
import {Place} from "@app/interfaces";
import {TimelineEntry} from "@interfaces/timeline-entry";
import {addIcons} from "ionicons";
import {close} from "ionicons/icons";
import {PreferencesService} from "@services/preferences.service";
import {NgIcon} from "@ng-icons/core";
import {AuthService, MessagingService} from "@app/services";
import {UpdateNewsSheetService} from '@app/services/update-news-sheet.service';
import {UpdateEventSheetService} from "@services/update-event-sheet.service";

@Component({
  selector: 'app-preferences-sheet',
  templateUrl: './preferences-sheet.component.html',
  styleUrls: ['./preferences-sheet.component.scss'],
  imports: [
    IonHeader,
    IonToolbar,
    IonTitle,
    IonList,
    IonItem,
    IonButtons,
    IonButton,
    IonIcon,
    NgIcon
  ]
})
export class PreferencesSheetComponent {

  public preferencesService: PreferencesService = inject(PreferencesService);

  protected authService: AuthService = inject(AuthService);

  private el: ElementRef = inject(ElementRef);
  private toastController: ToastController = inject(ToastController);
  private messagingService: MessagingService = inject(MessagingService);
  private alertController: AlertController = inject(AlertController);
  private updateNewsSheetService: UpdateNewsSheetService = inject(UpdateNewsSheetService);
  private updateEventSheetService: UpdateEventSheetService = inject(UpdateEventSheetService);

  @Input() public place: Place | undefined;
  @Input() public timelineEntry: TimelineEntry | undefined;

  constructor() {
    addIcons({close})
  }

  async disableNews() {
    if (!this.timelineEntry) {
      console.warn('No timeline entry');
      return;
    }

    await this.preferencesService.disableEntry(this.timelineEntry.owner, 'news');
    const toast = await this.toastController.create({
      message: `News für ${this.timelineEntry.owner.title} wurde deaktiviert.`,
      duration: 2000
    });
    await toast.present();
    this.close();
  }

  async disableEvents() {
    if (!this.timelineEntry) {
      console.warn('No timeline entry');
      return;
    }

    await this.preferencesService.disableEntry(this.timelineEntry.owner, 'event');
    const toast = await this.toastController.create({
      message: `Events für ${this.timelineEntry.owner.title} wurde deaktiviert.`,
      duration: 2000
    });
    await toast.present();
    this.close();
  }

  async disablePlace() {
    if (!this.timelineEntry) {
      console.warn('No timeline entry');
      return;
    }

    await this.preferencesService.disablePlace(this.timelineEntry.owner);
    const toast = await this.toastController.create({
      message: `Events und News für ${this.timelineEntry.owner.title} wurden deaktiviert.`,
      duration: 2000
    });
    await toast.present();
    this.close();
  }


  async enableNotifications() {
    if (!this.timelineEntry) {
      console.warn('No timeline entry');
      return;
    }

    let hasPermission = await this.messagingService.checkPermissions();
    if (!hasPermission) {
      await this.messagingService.requestPermissions();
    }

    hasPermission = await this.messagingService.checkPermissions();
    console.log(`${this.constructor.name}.enableNotifications: hasPermission`, hasPermission);

    if (!hasPermission) {
      const alert = await this.alertController.create({
        header: 'Benachrichtigungen deaktiviert',
        message: 'Benachrichtigungen sind deaktiviert. Bitte aktiviere die Benachrichtigungen in den App-Einstellungen.',
        buttons: ['OK'],
      });
      await alert.present();
      return;
    }

    await this.preferencesService.enableNotification(this.timelineEntry.owner, this.timelineEntry.type);
    const toast = await this.toastController.create({
      message: `Benachrichtigungen für ${this.timelineEntry.owner.title} wurden aktiviert.`,
      duration: 2000
    });
    await toast.present();
    this.close();
  }

  async disableNotifications() {
    if (!this.timelineEntry) {
      console.warn('No timeline entry');
      return;
    }

    await this.preferencesService.disableNotification(this.timelineEntry.owner, this.timelineEntry.type);
    const toast = await this.toastController.create({
      message: `Benachrichtigungen für ${this.timelineEntry.owner.title} wurden deaktiviert.`,
      duration: 2000
    });
    await toast.present();
    this.close();
  }

  async updateNews() {
    if (!this.timelineEntry) {
      console.warn('No timeline entry');
      return;
    }

    if (!this.authService.canUpdate(this.timelineEntry)) {
      console.warn('User not allowed to update');
      return
    }

    this.close();
    await this.updateNewsSheetService.open(this.timelineEntry.toNews());
  }

  async updateEvent() {
    if (!this.timelineEntry) {
      console.warn('No timeline entry');
      return;
    }

    if (!this.authService.canUpdate(this.timelineEntry)) {
      console.warn('User not allowed to update');
      return
    }

    this.close();
    await this.updateEventSheetService.open(this.timelineEntry.toEvent());
  }

  close() {
    this.el.nativeElement.dispatchEvent(new CustomEvent('close-preferences', {bubbles: true}));
  }
}
