import {Component, ElementRef, inject, Input} from '@angular/core';
import {IonButton, IonIcon, IonLabel} from "@ionic/angular/standalone";
import {Article} from "@interfaces/article";
import {Event, News} from "@app/interfaces";
import {CameraService} from "@app/services";
import {FormGroup} from "@angular/forms";
import {addIcons} from "ionicons";
import {camera} from "ionicons/icons";
import {Poll} from "@interfaces/poll";

@Component({
  selector: 'app-take-picture',
  templateUrl: './take-picture.component.html',
  styleUrls: ['./take-picture.component.scss'],
  imports: [
    IonButton,
    IonIcon,
    IonLabel
  ]
})
export class TakePictureComponent {

  @Input({required: true}) model: News | Event | Article | Poll;
  @Input({required: true}) hasPhoto: boolean = false;
  @Input({required: true}) form: FormGroup;

  private el: ElementRef = inject(ElementRef);
  private cameraService: CameraService = inject(CameraService);

  constructor() {
    console.log(`${this.constructor.name}.constructor`);
    addIcons({camera})
  }

  async takePicture() {
    try {
      const imageBase64 = await this.cameraService.takePictureToBase64String();

      this.form.patchValue({photo: imageBase64});

      if (imageBase64) {
        this.el.nativeElement.querySelector('#photo').src = 'data:image/png;base64,' + imageBase64;
        this.hasPhoto = true;
      }
    } catch (error) {
      // ignore
    }
  }

  removeImage(photo: string) {
    this.model.images = this.model.images.filter(image => image !== photo);
  }
}
