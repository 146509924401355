import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {A2hsService} from "@services/a2hs.service";
import {IonicModule} from "@ionic/angular";

@Component({
  selector: 'app-a2hs-browser-prompt',
  imports: [CommonModule, IonicModule],
  templateUrl: './a2hs-browser-prompt.component.html',
  styleUrl: './a2hs-browser-prompt.component.scss'
})
export class A2hsBrowserPromptComponent {

  constructor(public a2hs: A2hsService) {
  }
}
